<template>
  <div>
    <h3>Add Property Category</h3>
    <div class="row" v-if="! loading">
      <div class="col-md-8">
        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Name</label>
                <input type="text" placeholder="Name" v-model="category.name" required class="form-control">
              </div>
              <div class="form-group">
                <label>Parent</label>
                <v-select label="name" :options="categories" placeholder="Select Parent Category" v-model="selected_category" class="form-control" />
              </div>
              <div class="form-group text-end">
                <button class="btn btn-theme"><i class="lni lni-save me-2"></i>Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      category: {},
      categories: [],
      selected_category: null,
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/admin/categories').then(response => {
        this.categories = response.data.categories
        this.loading = false
      })
    },

    submit() {
      let data = {
        name: this.category.name,
        parent: this.selected_category == null ? null : this.selected_category.id
      }

      this.axios.post('/api/v1/admin/categories', data).then(() => {
        this.$router.push({ name: 'admin.categories.index' })
        this.$swal({
          title: "Created",
          text: "The category has been created",
          type: 'success'
        })
      })
    }
  }
}
</script>